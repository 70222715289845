$(document).ready(function ()
{
    setTimeout(function() {
        $('#missatge').fadeOut('fast');
    }, 30000);

    $('.datepicker').datepicker({
        language: 'ca',
        todayBtn: "linked",
        format:"dd/mm/yyyy",
        keyboardNavigation: false,
        forceParse: false,
        calendarWeeks: true,
        autoclose: true
    });

    $('.clockpicker').clockpicker();


    // Delete record
    $(document).delegate('.btn-modal-delete', 'click', function (e)
    {
        var table = $('.dataTable').DataTable();

        e.preventDefault();

        var $url = $(this).data('url'),
            $tr = table.row($(this).parents('tr'));

        $('#confirm').modal({ backdrop: 'static', keyboard: false }).one('click', '#delete', function ()
        {
            $.ajax({
                url: $url,
                type: 'DELETE',
                data: {'_token': $('meta[name="csrf-token"]').attr('content')},
                success: function() {
                    $tr.remove().draw(false); // the false param helps drawing the table without changing pagination status.
                }
            });
        });
    });

});
